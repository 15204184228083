.App {
  /*text-align: center;*/
}
h1 {
  margin-top: 10px;
margin-bottom: 10px}


.noborder > .ant-card-head {
  border-bottom: none !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /*align-items: left;*/
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
}





body{
  background-color: #f6f7fb;
}

.ant-layout-sider{
  background-color: #ffffff !important;
}














/*
.App-link {
  color: #61dafb;
}

 */

.demo-logo-vertical {
  height: 32px;
  height: 60px;
  margin: 16px;
  background: rgba(255,255,255,.2);
  border-radius: 6px;
}

.ant-layout{
  background-color: #fff;
  /*  background-color: rgba(9, 33, 79, 1)
  background-color: transparent;*/
}

.ant-layout-sider {
  background-color: #fff !important;
  background-image: url("/img/loginbackground.jpeg") !important;
  background-size: cover;
  background-position: center center;
  color: #fff !important;
}


.ant-menu, .ant-layout-sider, .ant-layout-sider-dark, .ant-menu-item-disabled{
  color: #fff !important;
background-color: transparent;
  /*
    background-color: rgba(9, 33, 79, 1) !important;
    background-color: #138991 !important;
    background-image: linear-gradient(to bottom right, #158a91, #257f88) !important;;*/
}

.transporter-gui{
  /*background-color: rgb(9, 56, 148) !important;*/
  background-color: #fff !important;

}

.operator-gui{
  
  /*background-color: rgba(9, 33, 79, 1) !important;*/
  background-color: #fff !important;
}



.ant-affix{
background-color:  #f4f6f9 !important;
}



.ant-menu-item {
  padding: 0px 30px !important;
  color: #fff !important;
}

 


.ant-menu-item >.anticon {
  margin-right: 15px !important;
  float : right !important;
  margin-top: 10px;
  color: #fff !important;
  
}

.ant-menu-item-disabled >.anticon {
  margin-right: 15px !important;
  float : right !important;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.25) !important;
  /*color: #fff !important;*/

}



.ant-menu-item-selected{
  /*background: rgba(9, 33, 79, 1);*/
 background: #0e2640 /*rgba(20, 73, 174, 1) */!important;
  color: #fff !important;
}

h1{
  color: #0c5e9b !important;
  font-weight: 600 !important;
}

.ant-menu-item-divider
{
  margin: 10px 0px 25px 20px !important;
}


.ant-layout-header{
  /*border-radius: 16px 16px 0px 0px;*/
  background: rgb(244, 246, 249, 1) !important;
  
}
.ant-layout-content{
  /*border-radius: 0px 0px 16px 16px;*/
  background: rgb(244, 246, 249, 1) !important; 
}

.ant-layout-sider-children{
  margin: 0px 10px;
}

.ant-menu-item-disabled > .ant-menu-title-content{
  /*color: #fff !important;
  font-weight: 600;
  font-size: 18px;
  margin-top: 8px;*/
}
.ant-menu-item-divider
{
  border-color: rgb(255 255 255 / 20%) !important;
}

.ant-layout-header, .ant-layout-content{
padding: 0px 24px;
}

.ant-menu-vertical{
  border-inline-end: none !important;
}


.ant-menu-item-disabledlogo {
  height: 80px !important;
  background-color: transparent !important;
}

/*
.ant-menu-item-disabled {
  height: 80px !important;
}

 */




.ant-menu, .ant-layout-sider, .ant-layout-sider-dark, .ant-menu-item-disabled, .ant-layout{
 /* background: rgba(9, 33, 79, 1) !important;*/
}



.ant-layout-has-sider,  .ant-menu, .ant-layout-sider, .ant-layout-sider-dark, .ant-menu-item-disabled, .ant-layout{
 /* background-color: rgba(9, 33, 79, 1);*/
}








#driverstab{
  border-radius: 16px;
  padding: 16px ;
}

.actionLink{
  font-size: 14px;
}

h1{
  font-weight: normal;
  font-size: 24px;
  margin-bottom: 35px;
  color: #6B6E74;  
}
.orangeButton
{
  background-color: #0c5e9b /*#E3601D*/;
  color: #fff;
  /*border-radius: 16px;*/
  font-size: 14px;
}
.orangeButton:hover
{
  color: #fff !important;
  border-color: #1b87d7 !important;
  background-color: #1b87d7 !important;

}


.lporangeButton
{
  background-color: #0e2640;
  color: #fff;
  /*border-radius: 16px;*/
  font-size: 14px;
}


.lporangeButton:hover
{
  color: #fff !important;
  border-color: #0d2640 !important;
  background-color: #0e2640 !important;

}

.padding5 > .ant-card-body{
  padding: 5px !important
}

.noborder {
  border: none !important
}

.scenario2 {
  border-color: #02BBD5
}

.scenario3 {
  border-color: #BC82B7
}

.greyzone{
  color: #999 !important;
}

.noborder > .ant-card-body{
  border: none !important
}

.emptyB{
  background-color: transparent !important;
}


.emptyB input{
  background-color: transparent !important;
}


.orangeA{
  color: #E3601D;
}
.orangeA:hover{
  color: #E3601D !important;
  border-bottom: 1px solid #E3601D;
}

.emptyButton{
  background-color: transparent;
  color: #1c7884;
  border-color: #1c7884 !important;
  /*border-radius: 16px;*/
  font-size: 14px;
}

.ant-anchor:before{
 border-inline-start: 1px solid rgba(5, 5, 5, 0.06) !important; 
}

.ant-anchor-link-title-active{
  color: #0c5e9b !important;
}

.text-center{
  text-align: center;
}
.roundImage{
  border-radius: 50%;
}
.emptyButton{
  background-color: transparent;
  color: #0c5e9b !important;
  border-color: #0c5e9b !important;
}





.ant-tabs-nav:before{
  border-bottom: 1px solid #D7D9DC !important;
}

.ant-checkbox-inner:checked {
 /* background-color: #1c7884 !important;*/
}

.ant-tabs-ink-bar{
  background: #0c5e9b/*#e3601e*/ !important;
  height: 3px !important;
}

.ant-tabs-tab-btn{
  color: #A4A6AC !important;
}

.ant-tabs-tab-active > .ant-tabs-tab-btn{
 /* color: #000 !important;*/
}

.ant-form-item{
  margin-bottom: 15px;
}

body{
  /*background-color: rgba(9, 33, 79, 1);*/
  /*background-color: #fff;*/

}

th.ant-table-cell {
 background-color: #fff !important;
  font-weight: normal !important;
  font-size: 12px;
  color: #A4A6AC !important;
}


.between{
  display: flex;
  justify-content: flex-end;
  min-width: 8px;
  height: 16px;
  position: relative;
  grid-area: routePointBetwen;
}

.between:after, .between_o9LuG:before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 1;
}


.between:before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 1;
  border-bottom: 1px solid #C6C6C6;
  left: 0;
}

.between:after {
  border-right: 1px solid #C6C6C6; 
  border-top: 1px solid #C6C6C6;
  height: 5px;
  margin-top: -2px;
  transform: rotate(45deg);
  width: 5px;
}

.RoutePoints{
  max-width: 450px;
  /*display: flex;*/
  display: grid;
  width: 100%;
  align-items: center;
  grid-column-gap: 8px;
  grid-template-areas: "routePointStart routePointBetwen routePointEnd";
  grid-template-columns: minmax(0,min-content) minmax(min-content,1fr) calc(50% - 24px);
  min-height: 20px;
}
.point{
  display: flex;
  padding: 0px 5px;
  text-align: left !important;
  max-width: 180px;
}


.name_sCDti{
  overflow: hidden;
  text-overflow: ellipsis;
}

.name_eCDti{
  overflow: hidden;
  text-overflow: ellipsis;
  width:170px;
}
.timeStart{
  text-align: right;
  margin-right: 15px;
  display: flex;
  margin-top: 6px;
  font-size: 10px;
}
.timeEnd{
  text-align: left;
  margin-left: 15px;
  display: flex;
  margin-top: 6px;
  font-size: 10px;

}

.start{
  grid-area: routePointStart;
}

.end{
  grid-area: routePointEnd;
  width: 190px;


}
.greyLabel {
  color: #A4A6AC;
  font-size: 12px;
  vertical-align: middle;
}

.routeTime{
  color: #A4A6AC;
  font-size: 10px;
  vertical-align: middle;
}

.dot {
  height: 8px;
  width: 8px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.traktor_div ,  .priklopnik_div{
  display: inline-block;
  width: 80px;
  font-size: 12px;
}
.priklopnik_div{
text-align: right;
}
.traktor_div>img{
  display: block;
  height: 15px;
}

.tovornjak_div{
  width: 120px;
  font-size: 12px;
}

.priklopnik_div>img{
  height: 15px;
  display: block;
  float: right;
}

.tovornjak_div>img{
  height: 15px;
  display: block;
}

.routePointEditSuffix{
  
  margin-left: 15px;
  padding-left: 15px;
  min-height: 75px;
  vertical-align: top;
 
  /*
  border-left: 2px solid #e6e8ea;
  font-size: 20px;
  */
}


.ant-form-item-label{
/*  flex: 0 !important;*/
}

.orangeclicked {
  /*background-color: #E3601D;*/
  color: #E3601D;
  border: 2px solid #E3601D;
  font-weight: bolder;
}

.ant-steps-item-tail:after{
  height: 1px !important;
}



.dougnat_chart > .ant-card-head{
  min-height: 0px !important;
  border-bottom: 0px;

}


.dougnat_chart > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding: 5px 24px 0px 24px !important;
  font-size: 14px !important;
 /* line-height: 10px !important;*/
}



.location-search-input{
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
}

.autocomplete-dropdown-container {
  background-color: #fff;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  padding: 10px;
  position: absolute;
  width: 100%;
  z-index: 5000;
}

.autocomplete-dropdown-container:empty {
  display: none;
}

.ant-steps-item{
  height: 130px;
/*  margin-top: 70px;*/
}


.dealsstep > .ant-steps-item{
  height: 20px;
  /*  margin-top: 70px;*/
}


.ant-steps-item-finish{
  height: 75px;
}

.ant-steps-item:first-child{
  margin-top: 0px !important;
}


.ant-steps-item-description{
  position: absolute;
  width: 90%;
}


.RequestStatuses .ant-steps-item-title{
  font-size: 12px !important;
} 

.EditRequestCard{
  padding: 0px !important;
}

.EditRequestCard .ant-card-body{
  padding: 0px !important;
}




.float-label .ant-select-selection-item{
  margin-top: 15px !important;
}

.float-label .ant-input-number-input-wrap{
  margin-top: 15px !important;
}

.bordered-right .ant-select-selector{
  /*border-radius: 0px;*/
  border-radius: 0px 5px 5px 0px !important;
  border-left: 1px solid #fff !important;
}

.bordered-right .ant-select-selector:hover{
  border-left: 1px solid #1677ff !important;
}
.bordered-right .ant-select-selector:focus{
  border-left: 1px solid #1677ff !important;
}

.bordered-right .ant-select-selector:active{
  border-left: 1px solid #1677ff !important;
}


.bordered-left .ant-select-selector{
  /*border-radius: 0px;*/
  border-radius: 5px 0px 0px 5px !important;
}


.normalHeader{
  background-color: #f4f6f9;
}

.redHeader{
  background-color:  red !important;
}

.redHeadera{
  color : #fff !important;
  border-bottom: 1px solid #fff;
}


.ContractorHintItem:hover{
  
}

.ant-progress-inner{
  height: 2px !important; 
}

.nobg {
  background-color: transparent !important;
  box-shadow: none !important;
}

.nopadding > .ant-card-body {
  padding: 10px 24px !important;
}

.ant-timeline-item-head{
  background-color: transparent !important;
}


input {
  outline: none !important;
 /* border: 1px solid transparent !important;*/
}


.sauftragText{
  font-family: "Arial";
  font-weight: bold;
  position: relative;
}

tr.sauftragText td{
  border: 1px solid #ffffff;
}


.sauftragLabel{
  font-family: "Arial";
  font-weight: normal;
  font-size: 12px;
  color: #6B6E74;
}

.sauftragTable td{
  border: 1px solid #000;
  vertical-align: top;
}


.showformelem{
  background-color: #f4f6f9 !important;
  color: #000 !important;
}

.ContractorsTable > td{
  cursor: pointer;
}


.myNumber >  .ant-input-number-input{
  text-align: right !important;
}

.subheader > td{
/*background-color: #bbbbbb;*/
  border-top: 1px solid #eee  !important;
  border-bottom: 0px solid #fff !important;
}


.subheader > td:first-child{
  border-right: 1px solid #eee  !important;
}

.ddstable > td:first-child{
  border-right: 1px solid #eee  !important;
}

.ddstable > td{
  /*border-top: none;*/
  border-bottom: 0px solid #fff !important;
  border-top: 0px solid #fff  !important;
}

.greendot{
  height: 8px;
  width: 8px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  background-color: rgb(31, 170, 115);
  vertical-align: middle;
}

.orangedot{
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  background-color: #E3601D;
  vertical-align: middle;
}

.graydot{
  height: 8px;
  width: 8px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}

.isPlan{
  opacity: 0.5;
}



@media print {
  footer {page-break-after: always;}
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
